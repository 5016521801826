<template>
  <Nuxt />
</template>

<script setup lang="ts">
</script>

<script lang="ts">
export default defineNuxtComponent({
  name: 'LayoutTest',
})
</script>

<style lang="scss"></style>
